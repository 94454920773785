var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sessions"}},[_vm._m(0),(_vm.sessionsSorted.length === 0)?_c('div',{staticClass:"warning"},[_c('a-row',{staticClass:"section-header",attrs:{"justify":"center"}},[_c('a-col',{attrs:{"md":{ span: 10, offset: 7 },"xs":{ span: 22, offset: 1 }}})],1)],1):_c('div',{staticClass:"sessions-list"},[(_vm.sessions)?_c('a-row',{attrs:{"justify":"center"}},[_c('a-col',{attrs:{"md":{ span: 10, offset: 7 },"xs":{ span: 22, offset: 1 }}},[(_vm.sessions)?_c('a-list',{staticClass:"my-10 divide-y divide-gray-200",attrs:{"loading":_vm.sessions.length === 0,"data-source":_vm.sessionsSorted},scopedSlots:_vm._u([{key:"renderItem",fn:function(session){return (session.status_name !== 'live_end')?_c('li',{staticClass:"my-3 md:flex overflow-hidden bg-white shadow",class:[
              session.status_name !== ('live' && 'recorded')
                ? 'opacity-50'
                : 'opacity-100',
              session.session_name === 'Main Stage'
                ? 'bg-brand text-white'
                : 'bg-white text-brand' ]},[_c('div',{staticClass:"md:h-32 h-58 w-54 flex-shrink-0 overflow-hidden"},[(
                  session.status_name === 'live' ||
                  session.status_name === 'recorded'
                )?_c('router-link',{attrs:{"slot":"title","to":{
                  name: 'LiveSessions',
                  params: {
                    projectSlug: _vm.projectSlug,
                    sessionId: session['.key'],
                  },
                }},slot:"title"},[_c('img',{staticClass:"h-full w-full object-cover object-center",attrs:{"src":session.video_thumbnail,"alt":session.session_name}})]):_c('a',{staticClass:"pointer-events-none",attrs:{"slot":"title"},slot:"title"},[_c('img',{staticClass:"h-full w-full object-cover object-center",attrs:{"src":session.video_thumbnail,"alt":session.session_name}})])],1),_c('div',{staticClass:"flex flex-1 flex-col"},[(
                  session.status_name === 'live' ||
                  session.status_name === 'recorded'
                )?_c('router-link',{staticClass:"h-full block p-4",attrs:{"slot":"title","to":{
                  name: 'LiveSessions',
                  params: {
                    projectSlug: _vm.projectSlug,
                    sessionId: session['.key'],
                  },
                }},slot:"title"},[_c('div',{staticClass:"\n                    flex\n                    justify-between\n                    text-base\n                    font-medium\n                    text-gray-900\n                  "},[_c('h3',{staticClass:"font-anuphan font-bold text-xl",class:[
                      session.session_name === 'Main Stage'
                        ? 'text-white'
                        : 'text-brand' ]},[_vm._v(" "+_vm._s(session.session_name)+" ")])]),(session.session_name === 'Main Stage')?_c('p',{staticClass:"mt-1 text-sm text-gray-500 font-sarabun"},[_vm._v(" Session ช่วงเช้า ")]):_c('p',{staticClass:"mt-1 text-sm text-gray-500 font-sarabun"},[_vm._v(" "+_vm._s(session.session_desc)+" ")])]):_c('div',{staticClass:"h-full block p-4",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"\n                    flex\n                    justify-between\n                    text-base\n                    font-medium\n                    text-gray-900\n                  "},[_c('h3',{staticClass:"font-anuphan font-bold text-xl",class:[
                      session.session_name === 'Main Stage'
                        ? 'text-white'
                        : 'text-brand' ]},[_vm._v(" "+_vm._s(session.session_name)+" ")])]),(session.session_name === 'Main Stage')?_c('p',{staticClass:"mt-1 text-sm text-white font-sarabun"},[_vm._v(" Session ช่วงเช้า ")]):_c('p',{staticClass:"mt-1 text-sm text-gray-500 font-sarabun"},[_vm._v(" "+_vm._s(session.session_name)+" ")])])],1)]):_vm._e()}}],null,true)}):_vm._e()],1)],1):_vm._e(),(_vm.sessions)?_c('a-row',{staticClass:"pb-10",attrs:{"justify":"center"}},[_c('a-col',{attrs:{"md":{ span: 10, offset: 7 },"xs":{ span: 22, offset: 1 }}},[_c('picture',[_c('source',{attrs:{"media":"(min-width:992px)","srcset":require('@/assets/img/icreator-2022-sponsors.jpg')}}),_c('source',{attrs:{"media":"(max-width:991px)","srcset":require('@/assets/img/icreator-2022-sponsors.jpg')}}),_c('img',{staticClass:"shadow overflow-hidden",attrs:{"src":require('@/assets/img/icreator-2022-sponsors.jpg'),"alt":"","title":"","width":"1309","height":"719","loading":"lazy"}})])])],1):_vm._e(),(_vm.project[0])?_c('VueScriptComponent',{attrs:{"script":_vm.project[0].external_script}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stages-header"},[_c('div',{staticClass:"banner"},[_c('h2',{staticClass:"title"},[_vm._v("SESSIONS")])])])}]

export { render, staticRenderFns }