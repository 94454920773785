<template>
  <div id="sessions">
    <!-- <a-row justify="center" class="section-header">
      <a-col :md="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }">
        <Announcement v-if="chatRoom" :room-id="chatRoom" />
      </a-col>
    </a-row> -->
    <div class="stages-header">
      <div class="banner">
        <h2 class="title">SESSIONS</h2>
      </div>
    </div>
    <div v-if="sessionsSorted.length === 0" class="warning">
      <a-row justify="center" class="section-header">
        <a-col :md="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }" />
      </a-row>
    </div>
    <div v-else class="sessions-list">
      <!-- <a-row justify="center" class="section-header">
        <a-col :md="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }">
          <h4 class="sessionlist-header">
            {{ $t('SESSION') }}
          </h4>
        </a-col>
      </a-row> -->
      <a-row v-if="sessions" justify="center">
        <a-col :md="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }">
          <a-list
            class="my-10 divide-y divide-gray-200"
            v-if="sessions"
            :loading="sessions.length === 0"
            :data-source="sessionsSorted"
          >
            <li
              v-if="session.status_name !== 'live_end'"
              class="my-3 md:flex overflow-hidden bg-white shadow"
              slot="renderItem"
              slot-scope="session"
              :class="[
                session.status_name !== ('live' && 'recorded')
                  ? 'opacity-50'
                  : 'opacity-100',
                session.session_name === 'Main Stage'
                  ? 'bg-brand text-white'
                  : 'bg-white text-brand',
              ]"
            >
              <div class="md:h-32 h-58 w-54 flex-shrink-0 overflow-hidden">
                <router-link
                  v-if="
                    session.status_name === 'live' ||
                    session.status_name === 'recorded'
                  "
                  slot="title"
                  :to="{
                    name: 'LiveSessions',
                    params: {
                      projectSlug: projectSlug,
                      sessionId: session['.key'],
                    },
                  }"
                >
                  <img
                    :src="session.video_thumbnail"
                    :alt="session.session_name"
                    class="h-full w-full object-cover object-center"
                  />
                </router-link>
                <a v-else class="pointer-events-none" slot="title">
                  <img
                    :src="session.video_thumbnail"
                    :alt="session.session_name"
                    class="h-full w-full object-cover object-center"
                  />
                </a>
              </div>

              <div class="flex flex-1 flex-col">
                <router-link
                  v-if="
                    session.status_name === 'live' ||
                    session.status_name === 'recorded'
                  "
                  slot="title"
                  :to="{
                    name: 'LiveSessions',
                    params: {
                      projectSlug: projectSlug,
                      sessionId: session['.key'],
                    },
                  }"
                  class="h-full block p-4"
                >
                  <div
                    class="
                      flex
                      justify-between
                      text-base
                      font-medium
                      text-gray-900
                    "
                  >
                    <h3
                      class="font-anuphan font-bold text-xl"
                      :class="[
                        session.session_name === 'Main Stage'
                          ? 'text-white'
                          : 'text-brand',
                      ]"
                    >
                      {{ session.session_name }}
                    </h3>
                  </div>
                  <p
                    v-if="session.session_name === 'Main Stage'"
                    class="mt-1 text-sm text-gray-500 font-sarabun"
                  >
                    Session ช่วงเช้า
                  </p>
                  <p v-else class="mt-1 text-sm text-gray-500 font-sarabun">
                    {{ session.session_desc }}
                  </p>
                </router-link>
                <div v-else slot="title" class="h-full block p-4">
                  <div
                    class="
                      flex
                      justify-between
                      text-base
                      font-medium
                      text-gray-900
                    "
                  >
                    <h3
                      class="font-anuphan font-bold text-xl"
                      :class="[
                        session.session_name === 'Main Stage'
                          ? 'text-white'
                          : 'text-brand',
                      ]"
                    >
                      {{ session.session_name }}
                    </h3>
                  </div>
                  <p
                    v-if="session.session_name === 'Main Stage'"
                    class="mt-1 text-sm text-white font-sarabun"
                  >
                    Session ช่วงเช้า
                  </p>
                  <p v-else class="mt-1 text-sm text-gray-500 font-sarabun">
                    {{ session.session_name }}
                  </p>
                </div>
              </div>
            </li>
            <!-- <a-tag v-if="session.status === 'live'" color="red">
                {{ $t('LIVE') }}
              </a-tag>
              <a-tag v-if="session.status === 'live_end'" color="purple">
                {{ $t('END') }}
              </a-tag>
              <a-tag v-if="session.status === 'recorded'" color="orange">
                {{ $t('RECORDED') }}
              </a-tag> -->
          </a-list>
        </a-col>
      </a-row>
      <a-row v-if="sessions" justify="center" class="pb-10">
        <a-col :md="{ span: 10, offset: 7 }" :xs="{ span: 22, offset: 1 }">
          <picture>
            <source
              media="(min-width:992px)"
              :srcset="require('@/assets/img/icreator-2022-sponsors.jpg')"
            />
            <source
              media="(max-width:991px)"
              :srcset="require('@/assets/img/icreator-2022-sponsors.jpg')"
            />
            <img
              :src="require('@/assets/img/icreator-2022-sponsors.jpg')"
              alt=""
              title=""
              width="1309"
              height="719"
              loading="lazy"
              class="shadow overflow-hidden"
            />
          </picture>
        </a-col>
      </a-row>
      <VueScriptComponent
        v-if="project[0]"
        :script="project[0].external_script"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import Announcement from '@/components/Announcement'
import logUser from '@/mixins/logUser'
import loginCheck from '@/mixins/loginCheck'
import 'dayjs/locale/th'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'
import customCssMixins from '@/mixins/customCss.js'
import VueScriptComponent from 'vue-script-component'

dayjs.extend(relativeTime)
dayjs.extend(isBetween)
export default {
  name: 'Sessions',
  components: { VueScriptComponent },
  mixins: [logUser, loginCheck, customCssMixins],
  data() {
    return {
      now: dayjs(),
      project: [],
      projectSlug: null,
      projectName: null,
      chatRoom: null,
      sessions: [],
    }
  },
  metaInfo() {
    return {
      title: this.projectName,
      titleTemplate: '%s - LiveTube Portal',
    }
  },
  computed: {
    sessionsSorted() {
      const sessions = this.sessions
      const userData = JSON.parse(
        localStorage[`auth:live-tube-portal:${this.$route.params.projectSlug}`]
      )
      const sessionMapStatus = sessions
        .map((s) => {
          s.status_name = s.status
          s.status = this.sessionStatus(s.start_time, s.end_time)
          return s
        })
        .sort((a, b) => a.start_time - b.start_time)
      const sessionFilter = sessionMapStatus.filter((s) => {
        if (typeof userData.session_key !== 'undefined') {
          if (userData.session_key.length > 0) {
            return userData.session_key.includes(s['.key'])
          } else {
            return s
          }
        } else {
          return s
        }
      })
      return sessionFilter
    },
    sessionsAccess() {
      const userData = JSON.parse(
        localStorage[`auth:live-tube-portal:${this.$route.params.projectSlug}`]
      )
      return userData.session_key
    },
  },
  async created() {
    await this.detectLogin({
      name: 'Login',
      params: { projectSlug: this.$route.params.projectSlug },
    })

    await this.$binding(
      'sessions',
      this.$db
        .collection('sessions')
        .where('project_slug', '==', this.$route.params.projectSlug)
    )

    // if (this.sessions.length === 1) {
    //   this.$router.push({
    //     name: 'LiveSessions',
    //     params: {
    //       projectSlug: this.$route.params.projectSlug,
    //       sessionId: this.sessions[0]['.key'],
    //     },
    //   })
    // }

    await this.$binding(
      'project',
      this.$db
        .collection('project')
        .where('project_slug', '==', this.$route.params.projectSlug)
    )

    if (this.project[0]) {
      this.projectName = this.project[0].name
      this.chatRoom = this.project[0].rocket_chat_room_id
      this.$store.commit('changeMutation', this.project[0].logo)
    }
    this.projectSlug = this.$route.params.projectSlug
  },
  methods: {
    relativeTime(time) {
      dayjs.locale(this.$i18n.locale)
      return dayjs().to(time.toDate())
    },
    sessionTime(time) {
      dayjs.locale('th')
      return dayjs(time.toDate()).format('DD MMMM YYYY เวลา HH:mm น.')
    },
    sessionStatus(startTime, endTime) {
      startTime = dayjs(startTime.toDate())
      endTime = dayjs(endTime.toDate())

      if (this.now.isBefore(startTime)) {
        return 1
      }

      if (this.now.isBetween(startTime, endTime)) {
        return 0
      }

      if (this.now.isAfter(endTime)) {
        return 2
      }
    },
  },
}
</script>

<style>
#app {
  min-height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.section-header {
  margin-top: 0;
}
.sessionlist-header {
  font-family: 'anuphan';
  font-weight: 700;
}
.warning {
  margin-top: 35px;
}
</style>
